import { Suspense } from 'react';
import { Layout, Spin } from 'antd';
import { Outlet } from 'react-router-dom';
import Aside from './Aside';

import './Layout.less';

const { Header, Sider, Content } = Layout;

function Wrapper() {
  return (
    <Layout>
      <Header>Header</Header>
      <Layout>
        <Sider>
          <Aside />
        </Sider>
        <Layout className="layout-content">
          <Content className="layout-content-body">
            <Suspense fallback={<Spin size="large" />}>
              <Outlet />
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Wrapper;
