import { Menu, Space, Spin } from 'antd';
import { PageHeader } from '@felo/ui';
import { useTranslation } from 'react-i18next';
import { memo, useEffect, Suspense, useState } from 'react';
import Cookies from 'js-cookie';
import logo from '@/assets/images/logo.svg';
import './index.less';

const Title = () => {
  const { t } = useTranslation();
  const backHome = () => {
    window.location.href = '/';
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }} onClick={backHome}>
      <img style={{ width: 28, height: 24, marginLeft: 20 }} src={logo} />
      <div className="title-div">{t('RecommendationStatisticsSystem')}</div>
    </div>
  );
};
const Banner = () => {
  const teamIdStr = window.sessionStorage.getItem('teamId');
  const team_id = teamIdStr ? +teamIdStr : 0;
  const [initState, setInitState] = useState(false);
  useEffect(() => {
    setInitState(true);
  }, []);
  return (
    <div className="pc-banner">
      {
        initState ? <PageHeader
        sdk={window.FeloSDK} // 需要先给sdk设置appid
        colorMode={'light'} // 颜色模式 light | dark
        deviceMode={'pc'} // 平台模式 pc | mobile
        title={<Title/>} // 标题
        showMode={'normal'}
        teamId={team_id} // 需要登录的组织, 如果已登录信息里没有该组织则会默认选中非该id的一个组织
        anonymously={false} // 可匿名, 当未找到组织信息则会自动弹出选择帐号的弹框
        loginWithNewWindow={false}
        appVisible={false}
        personalInfoVisible={true}
        orgManagementVisible={false}
        onLoginInfo={async (teamInfo: any) => {
          if (!teamInfo) {
            window.sessionStorage.removeItem('teamId');
            window.sessionStorage.removeItem('token');
          }
          if (teamInfo.team_id.toString() === sessionStorage.getItem('teamId')?.toString()) {
            return;
          }
          // 切换组织后，把组织相关信息存储起来，然后刷新页面，重新获取数据。
          const newTeamId = teamInfo.team_id;
          window.sessionStorage.setItem('token', teamInfo.access_token);
          window.sessionStorage.setItem('teamId', newTeamId);
          window.sessionStorage.setItem('userName', teamInfo.userName);
          window.location.href = '/';
        }}
        onLogout={() => {
          console.log('onLogout...');
          window.sessionStorage.removeItem('teamId');
          window.sessionStorage.removeItem('token');
          Cookies.remove('fid', { path: '' });
          Cookies.remove('vid', { path: '' });
          Cookies.remove('Authorization', { path: '' });
          // window.location.reload();
          window.location.href = '/';
        }}
      /> : <div></div>
      }

    </div>
  );
};

export default Banner;
