import service from './http';

// 管理员创建员工
export const createStaff = (
  bank_card_no: string, // 银行卡号
  contact_email: string, // 联系邮箱
  contact_phone: string, // 联系电话
  contacts: string, // 联系人
  felo_open_id: string, // openid
  memo: string, // 备注
) =>
  service.request({
    method: 'post',
    url: '/proxy/create.staff',
    data: {
      bank_card_no,
      contact_email,
      contact_phone,
      contacts,
      felo_open_id,
      memo,
    },
  });

// 供应商&管理员-查看自己或某代理商的代理关系列表
export const queryUserByEmail = (email: string) =>
  service.request({
    method: 'post',
    url: `/proxy/query.felo.user.info.by.email?email=${email}`,
  });

// 管理员-查看某代理商的代理关系列表
export const queryAdminRelationList = (open_id: string, current: number) =>
  service.request({
    method: 'post',
    url: '/proxy/admin.query.proxy.relation.list',
    data: {
      open_id,
      current,
      size: 10,
    },
  });

// 经销商-查看的代理关系列表
export const queryRelationList = (current: number) =>
  service.request({
    method: 'post',
    url: '/proxy/query.proxy.relation.list',
    data: {
      current,
      size: 10,
    },
  });
// 供应商&管理员-查询自己的员工信息
export const querySelfInfo = () =>
  service.request({
    method: 'post',
    url: '/proxy/query.self.info',
  });

// 供应商-查询自己的邀请码
export const queryInviteCode = () =>
  service.request({
    method: 'post',
    url: '/proxy/query.self.invite.code',
  });

// 供应商-查询自己的邀请码
export const team = () =>
  service.request({
    method: 'post',
    url: '/internal/team.plan.get',
    data: {
      team_id: 57469369,
    },
  });
// 查看在线的会议列表
export const meetJoinedOnlineQuery = () =>
  service.request({
    method: 'post',
    url: '/felo-minutes/public/meet/meetJoinedOnlineQuery',
  });

//  管理员-查询经销商列表
export const queryStaffList = (current: number) =>
  service.request({
    method: 'post',
    url: '/proxy/query.staff.list',
    data: {
      current,
      size: 10,
    },
  });
// (管理员 - 查询经销商信息);
export const queryStaffInfo = (openId: String) =>
  service.request({
    method: 'post',
    url: '/proxy/query.staff.info',
    params: {
      openId,
    },
  });

// 管理员 - 编辑经销商;
export const editStaff = (
  bank_card_no: string, // 银行卡号
  contact_email: string, // 联系邮箱
  contact_phone: string, // 联系电话
  contacts: string, // 联系人
  open_id: string, // openid
  memo: string, // 备注
) =>
  service.request({
    method: 'post',
    url: '/proxy/edit.staff',
    data: {
      bank_card_no,
      contact_email,
      contact_phone,
      contacts,
      open_id,
      memo,
    },
  });
