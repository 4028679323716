import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Space, Button, Pagination, Table } from 'antd';
import SubMenu from '@/components/subMenu/index';
import AddAgency from './addAgency/index';
import DetailsAgency from './detailsAgency/index';
import HighSeasData from './highSeasData/index';
import { queryStaffList } from '@/api/api';
import './index.less';

function AgencyList() {
  const { t } = useTranslation();
  const { Search } = Input;
  const [data, setData] = useState([]);
  const [curr, setCurr] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageType, setPageType] = useState(0);
  const [item, setItem] = useState({});
  const [subMenuText, setSubMenuText] = useState('');
  const [subMenuClick, setsubMenuClick] = useState(undefined);

  const detailsClick = (record:any) => {
    setItem(record);
    setSubMenuText(record.organization);
    setPageType(2);
  };
  // 我的内容表头
  const column = [
    {
      title: t('organization'),
      dataIndex: 'organization',
    },
    {
      title: t('agencyAccount'),
      dataIndex: 'agencyAccount',
    },
    {
      title: t('operation'),
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => detailsClick(record)}>{t('check')}</a>
        </Space>
      ),
    },
  ];
  const onChange = () => {};
  const onPageChange = (page:number, size:number) => {
    queryStaffList(page).then((res) => {
      const list:any = [];
      for (let i = 0; i < res.data.data.records.length; i++) {
        res.data.data.records[i].organization = res.data.data.records[i].proxy_staff.felo_team.team_name;
        res.data.data.records[i].agencyAccount = res.data.data.records[i].proxy_staff.fele_user?.email;
        res.data.data.records[i].key = i;
        res.data.data.records[i].bank_card_no = res.data.data.records[i].proxy_staff.bank_card_no;
        res.data.data.records[i].contact_email = res.data.data.records[i].proxy_staff.contact_email;
        res.data.data.records[i].contacts = res.data.data.records[i].proxy_staff.contacts;
        res.data.data.records[i].contacts_phone = res.data.data.records[i].proxy_staff.contacts_phone;
        res.data.data.records[i].memo = res.data.data.records[i].proxy_staff.memo;
        list.push(res.data.data.records[i]);
      }
      setData(list);
      setTotal(res.data.data.total);
      setCurr(res.data.data.current);
    });
  };
  const addClick = () => {
    setItem({});
    setPageType(1);
    setSubMenuText(t('addAgency'));
    setsubMenuClick(undefined);
  };
  const getDataInfo = () => {
    queryStaffList(1).then((res) => {
      const list = [];
      for (let i = 0; i < res.data.data.records.length; i++) {
        res.data.data.records[i].organization = res.data.data.records[i].proxy_staff.felo_team.team_name;
        res.data.data.records[i].agencyAccount = res.data.data.records[i].proxy_staff.fele_user?.email;
        res.data.data.records[i].key = i;
        res.data.data.records[i].bank_card_no = res.data.data.records[i].proxy_staff.bank_card_no;
        res.data.data.records[i].contact_email = res.data.data.records[i].proxy_staff.contact_email;
        res.data.data.records[i].contacts = res.data.data.records[i].proxy_staff.contacts;
        res.data.data.records[i].contacts_phone = res.data.data.records[i].proxy_staff.contacts_phone;
        res.data.data.records[i].memo = res.data.data.records[i].proxy_staff.memo;
        list.push(res.data.data.records[i]);
      }
      setData(list);
      setTotal(res.data.data.total);
      setCurr(res.data.data.current);
    });
  };
  const backMenu = () => {
    setPageType(0);
    setsubMenuClick(undefined);
    setSubMenuText('');
  };
  const checkClick = () => {
    setPageType(1);
  };
  const editBackClick = () => {
    setPageType(2);
  };
  const viewDataClick = () => {
    setSubMenuText(t('highSeasData'));
    setPageType(3);
  };
  useEffect(() => {
    getDataInfo();
  }, []);
  return (
    <div className="agency-content">
      <div className="content-title">
        <SubMenu menuText={t('agencyList')} menuClick={backMenu} subMenuText={subMenuText} />
        {/* <Search className="right-search" placeholder="input search text" onSearch={onSearch} style={{ width: 200 }} /> */}
      </div>
      <div className="content-center">
        {pageType === 0 ? (
          <div className="content-table">
            <Button className="add-button" icon={<PlusOutlined />} onClick={addClick} type="primary">
              {t('addAgency')}
            </Button>
            <Button className="add-button" onClick={viewDataClick} style={{ marginLeft: '24px' }}>
              {t('viewData')}
            </Button>
            <Table
              pagination={false}
              columns={column}
              bordered
              dataSource={data}
              onChange={onChange}
              className='agency-table'
              locale={{ emptyText: t('NoData') }}
              rowClassName={
                (record, index) => {
                  let className = '';
                  className = index % 2 === 0 ? 'ou' : 'ji';
                  // console.log(className)
                  return className;
                }
              }
            />
          </div>
        ) : (
          <div></div>
        )}
        {pageType === 0 ? (
          <div className="pagination-div">
            <Pagination
              current={curr}
              onChange={onPageChange}
              style={{ marginLeft: 'auto', marginRight: '20px' }}
              total={total}
              size="small"
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={() => `${t('total')} ${total} ${t('item')}`}
            />
          </div>
        ) : (
          <div></div>
        )}
        {pageType === 1 ? <AddAgency getDataInfo={getDataInfo} backMenu={backMenu} item={item}
          editBackClick={editBackClick}/> : <div></div>}
        {pageType === 2 ? <DetailsAgency item={item} backMenu={backMenu} checkClick={checkClick}/> : <div></div>}
        {pageType === 3 ? <HighSeasData backMenu={backMenu} checkClick={checkClick}/> : <div></div>}
      </div>
    </div>
  );
}

export default AgencyList;
