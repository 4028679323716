import { PropsWithChildren, useEffect, useMemo } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '@/hooks/auth';

export const AuthProtection = ({ children }: PropsWithChildren<unknown>) => {
  const location = useLocation();
  const { state, login } = useAuthContext();

  const queryParse = useMemo(() => {
    return (qs.parse(location.search, { ignoreQueryPrefix: true }) || {}) as { team_id?: string };
  }, [location.search]);

  useEffect(() => {
    const { team_id } = queryParse;

    const teamId = team_id ? parseInt(team_id, 10) : state.organization?.team_id;
    console.log('🚀 ~ file: index.tsx ~ line 18 ~ useEffect ~ teamId', teamId);
    login({ teamId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};
