import axios, { AxiosError } from 'axios';
import qs from 'qs';
import type { OAuthTokenData } from '@/types';

// 取消未完成的请求
// 用于存储pending的请求（处理多条相同请求）
const pendingRequest = new Map();

// 生成request的唯一key
const generateRequestKey = (config: any = {}) => {
  // 通过url，method，params，data生成唯一key，用于判断是否重复请求
  // params为get请求参数，data为post请求参数
  const { url, method, params, data } = config;
  return [url, method, qs.stringify(params), qs.stringify(data)].join('&');
};

// 将重复请求添加到pendingRequest中
const addPendingRequest = (config: any) => {
  const key = generateRequestKey(config);
  if (!pendingRequest.has(key)) {
    config.cancelToken = new axios.CancelToken((cancel) => {
      pendingRequest.set(key, cancel);
    });
  }
};

// 取消重复请求
const removePendingRequest = (config: any) => {
  const key = generateRequestKey(config);
  if (pendingRequest.has(key)) {
    const cancelToken = pendingRequest.get(key);
    cancelToken(key); // 取消之前发送的请求
    pendingRequest.delete(key); // 请求对象中删除requestKey
  }
};

let isRefreshing = false; // 标记是否正在刷新 token
let requests: any[] = []; // 存储待重发请求的数组
const instance = axios.create({
  baseURL: `${import.meta.env.VITE_FELO_API_URL}/api/v5/ext`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config: any) => {
    // 处理重复请求
    const token = window.sessionStorage.getItem('token');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    removePendingRequest(config);
    addPendingRequest(config);
    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => {
    // 移除重复请求
    removePendingRequest(response.config);
    return response;
  },
  (error: AxiosError) => {
    // 移除重复请求
    removePendingRequest(error.config || {});
    const { config } = error;
    if (error.response && error.response.status === 401 && error.response.data?.message) {
      return error.response;
    }
    if (error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        return window.FeloSDK.login({ redirectUrl: window.location.href })
          .then((data: OAuthTokenData[]) => {
            const teamId = window.sessionStorage.getItem('teamId');
            const selectedTeam = data.find((item) => item.team.team_id.toString() === teamId);
            if (selectedTeam) {
              const token = selectedTeam.login_info.access_token;
              window.sessionStorage.setItem('token', token);
              config.headers.authorization = `Bearer ${token}`;
              requests.forEach((cb) => cb(token));
              requests = []; // 重新请求完清空
              return instance(config);
            }
            return Promise.reject(error);
          })
          .catch((err: any) => Promise.reject(err))
          .finally(() => {
            isRefreshing = false;
          });
      }
      return new Promise((resolve) => {
        // 用函数形式将 resolve 存入，等待刷新后再执行
        requests.push((token: string) => {
          config.headers.authorization = `Bearer ${token}`;
          resolve(instance(config));
        });
      });
    }
    return Promise.reject(error);
  },
);

export default instance;
