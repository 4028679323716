import { PropsWithChildren } from 'react';
import Cookies from 'js-cookie';
import { useAuthContext } from '@/hooks/auth';

export const PrivateRoute = ({ redirectUrl, children }: PropsWithChildren<{ redirectUrl?: string }>) => {
  const { state } = useAuthContext();
  console.log(state);
  // TODO: redirect to index page
  const path = redirectUrl || '/';
  if (!state.authenticated || state.isAnonymous) {
    if (process.env.NODE_ENV === 'development') {
      return <div></div>;
    }

    Cookies.remove('fid', { path: '' });
    Cookies.remove('vid', { path: '' });
    Cookies.remove('Authorization', { path: '' });

    window.location.href = path;

    return null;
  }

  return <>{children}</>;
};
