import Routers from './Routes';
import 'antd/dist/antd.css';
import { AntdProvider } from './components/base/AntdProvider';
import { AuthProvider } from './components/AuthProvider/Provider';

window.FeloSDK.config({
  appId: 'yd9DDF0880AB034146AB3C73A10F6ED62A',
  mode: import.meta.env.VITE_STAGE === 'development' ? 'dev' : 'pro',
  origin: {
    dev: import.meta.env.VITE_FELO_TOKEN_URL,
    pro: import.meta.env.VITE_FELO_TOKEN_URL,
  },
});
console.log(process.env.NODE_ENV);
function App() {
  return (
    <AntdProvider>
      <AuthProvider>
        <Routers />
      </AuthProvider>
    </AntdProvider>
  );
}

export default App;
