import { Menu } from 'antd';
import { useMemo } from 'react';
import UnorderedListOutlined from '@ant-design/icons/UnorderedListOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';

function Aside() {
  const items = useMemo(() => [
    {
      key: 'dealer-list',
      icon: <UnorderedListOutlined />,
      label: '经销商列表',
    },
    {
      key: 'client-list',
      icon: <UnorderedListOutlined />,
      label: '客户列表',
    },
    {
      key: 'user',
      icon: <UserOutlined />,
      label: '账号信息',
    },
  ], []);

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      style={{ height: '100%' }}
      items={items}
    />
  );
}

export default Aside;
