import { useTranslation } from 'react-i18next';
import { Pagination, Table, Divider } from 'antd';
import { useState, useEffect } from 'react';
import chevronLeftSvg from '@/assets/images/chevron-left.svg';
import { queryAdminRelationList } from '@/api/api';
import peoplesSvg from '@/assets/images/peoples.svg';
import './index.less';

interface DetailsAgencyParams {
  backMenu: () => void;
  checkClick: () => void;
}

function DetailsAgency(props: DetailsAgencyParams) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [curr, setCurr] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageType, setPageType] = useState(0);
  const { backMenu, checkClick } = props;
  // 我的内容表头
  const column = [
    {
      title: t('registerAccount'),
      dataIndex: 'felo_account',
    },
    {
      title: t('organizationName'),
      dataIndex: 'felo_corp_name',
    },
  ];
  const onChange = () => {};
  const onPageChange = (page:number, size:number) => {
    queryAdminRelationList('10001', page).then((res) => {
      for (let i = 0; i < res.data.data.records.length; i++) {
        res.data.data.records[i].key = res.data.data.records[i].open_id;
      }
      setData(res.data.data.records);
      setTotal(res.data.data.total);
      setCurr(res.data.data.current);
    });
  };
  const pageSizeOptions = [10, 20, 50, 100];
  useEffect(() => {
    queryAdminRelationList('10001', 1).then((res) => {
      for (let i = 0; i < res.data.data.records.length; i++) {
        res.data.data.records[i].key = res.data.data.records[i].open_id;
      }
      setData(res.data.data.records);
      setTotal(res.data.data.total);
      setCurr(res.data.data.current);
    });
  }, []);
  return (
    <div className='details-content'>
      <div className="details-header">
        <div className="details-title">
          <img className="img-title" onClick={backMenu} src={chevronLeftSvg} />
          <div className="title-div">{t('highSeasData')}</div>
        </div>
      </div>
      <Divider />
        <div className="table-title">{t('dataList')}</div>
        <Table
          pagination={false}
          columns={column}
          dataSource={data}
          onChange={onChange}
          className="table-details"
          locale={{ emptyText: t('NoData') }}
          rowClassName={
            (record, index) => {
              let className = '';
              className = index % 2 === 0 ? 'ou' : 'ji';
              // console.log(className)
              return className;
            }
          }
          bordered
        />
        <div className="pagination-div">
          <Pagination
            current={curr}
            onChange={onPageChange}
            style={{ marginLeft: 'auto', marginRight: '20px' }}
            total={total}
            size="small"
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={() => `${t('total')} ${total} ${t('item')}`}
            pageSizeOptions={pageSizeOptions}
          />
        </div>
    </div>
  );
}

export default DetailsAgency;
