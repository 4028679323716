const userAgent = (typeof navigator !== 'undefined' ? navigator : { userAgent: '' }).userAgent.toLowerCase();

export const isServer = typeof navigator === 'undefined';
/**
 * 是否是 Edge 浏览器
 * Mozilla/5.0 (Windows NT 10.0 Win64 x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.140 Safari/537.36 Edge/17.17134
 */
export const isEdge = /edge/i.test(userAgent);
/**
 * 是否是 Chrome 浏览器
 * Mozilla/5.0 (Macintosh Intel Mac OS X 10_13_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/68.0.3440.106 Safari/537.36
 */

export const isChrome = !isEdge && /chrome/i.test(userAgent);
/**
 * 是否是 Firefox 浏览器
 * Mozilla/5.0 (Macintosh Intel Mac OS X 10.13 rv:62.0) Gecko/20100101 Firefox/62.0
 */
export const isFirefox = /firefox/i.test(userAgent);
/**
 * 是否是 Safari 浏览器
 * Mozilla/5.0 (Macintosh Intel Mac OS X 10_13_4) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.1 Safari/605.1.15
 */
export const isSafari = !isEdge && !isChrome && /safari/i.test(userAgent);
/**
 * 是否是 手机浏览器
 */
export const isMobile = /mobile/i.test(userAgent);
/**
 * 是否是iOS系统
 */

export const isIos = /os [._\d]+ like mac os/i.test(userAgent);
/**
 * 是否是 安卓系统
 */
export const isAndroid = /android/i.test(userAgent);
/**
 * 是否是 Mac OS X 系统
 */
export const isMacos = !isIos && /mac os x/i.test(userAgent);
/**
 * 是否是 Windows 系统
 */
export const isWindows = /windows\s*(?:nt)?\s*[._\d]+/i.test(userAgent);
/**
 * 是否是 Felo应用内
 */
export const isApp = /felo/i.test(userAgent);
/**
 * 是否是 IOS Felo应用内
 */
export const isIosApp = isApp && /safari/i.test(userAgent);
/**
 * 是否是 Android Felo应用内
 */
export const isAndroidApp = isApp && !isIosApp;
/**
 * 是否是 Ipad
 */
export const isIpad = /ipad/i.test(userAgent);

export const getLocaleTo = (path: string, prefix = '') => prefix + path;

// 获取当前语言
export const getCurrentLang = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const lang: string = window.sessionStorage.getItem('lang')!;

  return lang;
};
// 转换语言字段
const localesMap = {
  ja: 'ja-JP',
  en: 'en-US',
  'zh-Hant': 'zh-TW',
  zh_CN: 'zh-CN',
  zh: 'zh-TW',
} as const;

const localesMapValues = Object.values(localesMap);

export const getLocale = (str: string): 'zh-CN' | 'zh-HK' | 'zh-TW' | 'en-US' | 'ja-JP' | undefined => {
  if (localesMapValues.includes(str as any)) {
    return str as any;
  }

  return localesMap[str] || str;
};
//是否是在Iframe中
export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
// 跳转会议页面
export const openMeetPageForMeetID = (meetId: string) => {
  const path = `/${meetId}`;
  if (isApp) {
    FeloSDK.openBrowser(path);
  } else {
    window.open(path);
  }
};
function getUrl(str: string | any) {
  const reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
  str = str.match(reg);
  if (str && str.length > 0) {
    return str[0];
  }
  return null;
}
export const changeHref = (content: string) => {
  if (!content) {
    return '';
  }
  const link = getUrl(content);
  if (content) {
    return content.replace(link, ` <a target="_blank" href="${link}">${link}</a>`);
  }
  return content;
};

// 监测是否是全屏状态
export const checkFull = () => {
  let isFull =
    document.fullscreenElement ||
    document.msFullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    false;
  if (isFull === undefined) {
    isFull = false;
  }
  return Boolean(isFull);
};
