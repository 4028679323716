import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Select, message, Divider } from 'antd';
import { useEffect, useState, useRef } from 'react';
import type { FormInstance } from 'antd';
import chevronLeftSvg from '@/assets/images/chevron-left.svg';
import { editStaff, queryUserByEmail, createStaff } from '@/api/api';
import './index.less';

interface AddAgencyParams {
  backMenu: () => void;
  item:any;
  getDataInfo: () => void;
  editBackClick:()=>void
}

function AddAgency(props: AddAgencyParams) {
  const { t } = useTranslation();
  const { item, getDataInfo, backMenu, editBackClick } = props;
  const { Option } = Select;
  const [optionsList, setOptionsList] = useState([]);
  const inputRef = useRef('');
  const [helpLabel, setHelpLabel] = useState(t('inputAccount'));
  const [itemValidateStatus, setItemValidateStatus] = useState('error');
  const fromRef = useRef<FormInstance>(null);
  const onFinishFailed = () => {};
  useEffect(() => {
    if (item.agencyAccount) {
      setHelpLabel('');
      setItemValidateStatus('success');
    } else {
      setHelpLabel(t('inputAccount'));
      setItemValidateStatus('error');
    }
  }, [item]);
  const editInfo = (values: any) => {
    editStaff(
      values.bank_card_no,
      values.contact_email,
      values.contacts_phone,
      values.contacts,
      item.proxy_staff.open_id,
      values.memo,
    ).then((res) => {
      if (res.data.status.ok) {
        message.success(t('editSuccess'));
        getDataInfo();
        backMenu();
      } else {
        message.error(t('editError'));
      }
    });
  };
  const createInfo = (values: any) => {
    createStaff(
      values.bank_card_no,
      values.contact_email,
      values.contacts_phone,
      values.contacts,
      values.organization,
      values.memo,
    ).then((res) => {
      if (res.data.status.ok) {
        message.success(t('addSuccess'));
        getDataInfo();
      } else if (res.data.status.err_code === 'PROXY_EXISTED_STAFF') {
        message.error(t('addStatusErrot'));
      } else {
        message.error(t('addError'));
      }
    });
  };
  const onFinish = (values: any) => {
    if (item.agencyAccount) {
      editInfo(values);
    } else {
      createInfo(values);
    }
  };
  const backPage = () => {
    if (item.agencyAccount) {
      editBackClick();
    } else {
      backMenu();
    }
  };
  function onChangeTitle(event: any) {
    inputRef.current = event.target.value;
    queryUserByEmail(event.target.value).then((res) => {
      if (inputRef.current === event.target.value) {
        setOptionsList(res.data.data);
        if (res.data.data && res.data.data.length > 0) {
          const teamInfos = res.data.data;
          let createrTeam = false;
          for (let i = 0; i < teamInfos.length; i++) {
            if (teamInfos[i].felo_user_info?.id === teamInfos[i].felo_team?.creator?.id) {
              createrTeam = true;
            }
          }
          if (createrTeam) {
            setItemValidateStatus('success');
            setHelpLabel('');
          } else {
            setItemValidateStatus('error');
            setHelpLabel(t('noOrganizationCreated'));
            setOptionsList([]);
          }
        } else {
          setItemValidateStatus('error');
          setHelpLabel(t('notRegisteredFelo'));
        }
      }
    });
  }
  const selectOnChange = (value:string, itemValues:any) => {
    if (value !== itemValues.creator) {
      setItemValidateStatus('error');
      setHelpLabel(t('inputAccount'));
    } else {
      setItemValidateStatus('success');
      setHelpLabel('');
    }
  };
  const onValuesChange = (changedValues:any, allValues:any) => {
    if (changedValues.agencyAccount && allValues.organization) {
      allValues.organization = undefined;
      fromRef.current.setFieldsValue(allValues);
    }
  };
  return (
    <div className="form-div">
      <div className='form-title'>
        <img className='img-title' onClick={backPage} src={chevronLeftSvg}/>
        <span className='title-div'>{item.agencyAccount ? t('agencyDetails') : t('addAgency')}</span>
      </div>
      <div className='from-content'>
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={item}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className='form-custom'
          onFinish={onFinish}
          ref={fromRef}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            label={t('account')}
            name="agencyAccount"
            help={helpLabel}
            validateStatus={itemValidateStatus}
            rules={[{ required: true, message: t('notRegisteredFelo') }]}
          >
            <Input className='border-radius-two' placeholder={t('inputAccount')}
              disabled={!!item.agencyAccount} onChange={onChangeTitle}/>
          </Form.Item>

          <Form.Item
            label={t('organization')}
            name="organization"
            rules={[{ required: true, message: t('selectOrganization') }]}
          >
            <Select className='border-radius-two' onChange={selectOnChange}
              placeholder={t('selectOrganization')} disabled={!!item.agencyAccount}>
              {optionsList?.map((itemInfo: any) => (
                <Option key={itemInfo.felo_user_info.id} value={itemInfo.felo_user_info.id}
                  creator={itemInfo.felo_team.creator.id}>
                  {itemInfo.felo_team.team_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Divider/>
          <Form.Item
            label={t('contacts')}
            name="contacts"
            rules={[{ required: true, message: t('inputContacts') }]}
          >
            <Input className='border-radius-two' placeholder={t('inputContacts')} value={item.contacts}/>
          </Form.Item>
          <Form.Item
            label={t('emailAddress')}
            name="contact_email"
            rules={[{ required: true, message: t('inputEmailAddress') }]}
          >
            <Input className='border-radius-two' placeholder={t('inputEmailAddress')} value={item.contact_email}/>
          </Form.Item>
          <Form.Item
            label={t('phone')}
            name="contacts_phone"
            rules={[{ required: true, message: t('inputPhone') }]}
          >
            <Input className='border-radius-two' placeholder={t('inputPhone')} value={item.contacts_phone}/>
          </Form.Item>
          <Form.Item
            label={t('bankAccount')}
            name="bank_card_no"
          >
            <Input className='border-radius-two' placeholder={t('inputBankAccount')} value={item.bank_card_no}/>
          </Form.Item>
          <Form.Item
            label={t('remark')}
            name="memo"
          >
            <Input className='border-radius-two' placeholder={t('inputRemark')} value={item.memo}/>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button style={{ borderRadius: '4px' }} type="primary" htmlType="submit" >
            {item.agencyAccount ? t('edit') : t('add')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default AddAgency;
