import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import LinkSvg from '@/assets/images/link_t.svg';
import LinkSelectSvg from '@/assets/images/link_s.svg';
import InfoSvg from '@/assets/images/info.svg';
import InfoSelectSvg from '@/assets/images/info_s.svg';
import AgencyList from '../agencyList/index';
import AccountInfo from '../accountInfo/index';
import ClientList from '../clientList/index';
import { useAuthContext } from '@/hooks/auth';
import './index.less';

function Menu() {
  const { t } = useTranslation();
  const [currMenu, setCurrMenu] = useState(0);
  const { state } = useAuthContext();
  const { user } = state;
  const getMenuItem = (type:string) => {
    const info = user?.rule_types?.find((item:string) => item === type);
    return !!info;
  };
  useEffect(() => {
    const info = user?.rule_types?.find((item:string) => item === 'SYS_ADMIN');
    if (info) {
      setCurrMenu(0);
    } else {
      setCurrMenu(2);
    }
  }, [user]);
  return (
    <div className="home-content">
      {/* 左侧菜单 */}
      <div className="content-left">
        {
          getMenuItem('SYS_ADMIN') ? <div className="min-menu-item" onClick={() => setCurrMenu(0)}>
          <img className='min-img' src={currMenu === 0 ? LinkSelectSvg : LinkSvg}/>
          <div className="min-item-p" style={{ color: currMenu === 0 ? '#2994FF' : '#333333' }}>
            {t('agencyList')}
          </div>
        </div> : <div></div>
        }
        {
          getMenuItem('DISTRIBUTOR') ? <div className="min-menu-item" onClick={() => setCurrMenu(2)}>
          <img className='min-img' src={currMenu === 2 ? LinkSelectSvg : LinkSvg}/>
            <div className="min-item-p" style={{ color: currMenu === 2 ? '#2994FF' : '#333333' }}>
              {t('clientList')}
            </div>
          </div> : <div></div>
        }

        <div className="min-menu-item" onClick={() => setCurrMenu(1)}>
        <img className='min-img' src={currMenu === 1 ? InfoSelectSvg : InfoSvg}/>
          <div className="min-item-p" style={{ color: currMenu === 1 ? '#2994FF' : '#333333' }}>
            {t('accountInfo')}
          </div>
        </div>
      </div>
      {/* 右侧内容 */}
      <div className="content-right">
        {
          currMenu === 0 ? <AgencyList /> : <div />
        }
        {
          currMenu === 1 ? <AccountInfo /> : <div />
        }
        {
          currMenu === 2 ? <ClientList /> : <div />
        }
      </div>
    </div>
  );
}

export default Menu;
