import { PropsWithChildren, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { querySelfInfo, queryRelationList } from '@/api/api';
import { OAuthTokenData, OAuthTokenDecodeData, OrganizationUserDetailInfo, UserDetailInfo } from '@/types';
import { AuthContext, AuthState, initialState, Action, ActionKind } from './Context';
import { useEvent } from '@/hooks/useEvent';

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case ActionKind.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionKind.LOGIN:
      return {
        ...state,
        authenticated: true,
        isAnonymous: action.payload.isAnonymous,
        accessToken: action.payload.accessToken,
        user: action.payload.user,
        organization: action.payload.organization,
      };
    case ActionKind.LOGOUT:
      return {
        ...initialState,
      };
    case ActionKind.UPDATE_USER:
      return {
        ...state,
        // @ts-expect-error
        user: action.payload,
      };
    default:
      return state;
  }
};

export const makeUserInfo = (
  userInfo: any,
  accountInfo: OrganizationUserDetailInfo,
): UserDetailInfo => ({
  openId: accountInfo.id,
  userId: userInfo.userId,
  deptIds: accountInfo.dept_ids || [],
  type: accountInfo.type,
  userName: userInfo.userName || accountInfo.user_name,
  displayName: accountInfo.display_name,
  avatar: userInfo.userLogo || accountInfo.avatar,
  gender: accountInfo.gender,
  mobile: accountInfo.mobile,
  phone: accountInfo.phone,
  email: userInfo.email,
  personalStatus: accountInfo.personal_status,
  deleted: accountInfo.deleted,
  version: accountInfo.version,
  isAnonymous: userInfo.userChannel === 'ANONYMOUS',
  profile: userInfo.profile,
  userNumberId: userInfo.userNumberId,
  device: userInfo.device,
  teamId: accountInfo.team_id,
});

const getTokenData = (accessToken: string | undefined): OAuthTokenDecodeData | undefined => {
  if (!accessToken) {
    return undefined;
  }

  try {
    console.log(jwtDecode<OAuthTokenDecodeData>(accessToken));
    return jwtDecode<OAuthTokenDecodeData>(accessToken);
  } catch {
    return undefined;
  }
};

const getTokenExpireInfo = (data: OAuthTokenDecodeData) => {
  const now = Math.ceil(new Date().getTime() / 1000);

  const reaming = data.exp - now;

  // check if refresh token is expired
  return {
    reaming,
    expired: reaming < 60,
    token: data,
  };
};

const tokenHasExpired = (tokenData: OAuthTokenDecodeData) => tokenData && getTokenExpireInfo(tokenData).expired;

const getInitialState = (): AuthState => {
  const token = window.sessionStorage.getItem('token') || '';
  const teamId = window.sessionStorage.getItem('teamId') || '';
  const tokenData = getTokenData(token || '');

  if (!tokenData) {
    const isAnonymous = !token || !teamId;

    return { ...initialState, authenticated: false, isAnonymous };
  }

  const { expired } = getTokenExpireInfo(tokenData);

  const hasAnonymous = tokenData.key.indexOf('anonymous') !== -1;

  return {
    ...initialState,
    accessToken: token,
    authenticated: !expired,
    isAnonymous: hasAnonymous,
    organization: teamId
      ? {
        team_id: parseInt(teamId, 10),
        team_name: '',
        avatar: '',
      }
      : undefined,
  };
};

export function AuthProvider({ children }: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(reducer, initialState, getInitialState);
  const loginFromTeam = useEvent(async (teamId: number | undefined, teamList: OAuthTokenData[]) => {
    const { accessToken } = state;
    let valid = true;

    const tokenData = getTokenData(accessToken);
    // if (!accessToken || !tokenData || tokenHasExpired(tokenData)) {
    //   valid = false;
    // }

    if (tokenData?.key.indexOf('anonymous') === -1) {
      valid = false;
    }

    if (!valid) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('teamId');
    }
    const selectedTeam = teamList.find((item) => item.team.team_id === teamId) || teamList[0];

    if (!selectedTeam) {
      throw new Error('team not found');
    }

    const { login_info, account_info } = selectedTeam;

    window.sessionStorage.setItem('token', login_info.access_token);

    window.sessionStorage.setItem('teamId', selectedTeam.team.team_id.toString());
    window.sessionStorage.setItem('lang', account_info?.profile?.lang || '');
    try {
      const { data } = await querySelfInfo();
      if (data.data.fele_user) {
        data.data.account = data.data.fele_user.email;
      } else {
        data.data.account = '';
      }
      if (data.data.felo_team) {
        data.data.organization = data.data.felo_team.team_name;
      } else {
        data.data.organization = '';
      }

      dispatch({
        type: ActionKind.LOGIN,
        payload: {
          isAnonymous: false,
          accessToken: login_info.access_token,
          user: data.data,
          organization: selectedTeam.team,
        },
      });
    } catch (err) {
      dispatch({
        type: ActionKind.LOGIN,
        payload: {
          isAnonymous: false,
          accessToken: login_info.access_token,
          user: {},
          organization: selectedTeam.team,
        },
      });
    }
  });

  const loginByTeam = useEvent(async (teamId: number) => {
    const { organization } = state;

    if (organization?.team_id === teamId) {
      return;
    }

    const teamList: OAuthTokenData[] = await FeloSDK.getTeamList();

    let defaultInfo = teamList[0];

    if (!teamId) {
      // eslint-disable-next-line prefer-destructuring
      defaultInfo = teamList[0];
    } else {
      const result = teamList.find((item) => item.team.team_id === teamId);

      if (result) {
        defaultInfo = result;
      }
    }
    await loginFromTeam(defaultInfo.team.team_id, teamList);
  });

  const loginFromAnonymous = useEvent(async () => {
    let { accessToken } = state;

    let valid = true;

    const tokenData = getTokenData(accessToken);

    if (!accessToken || !tokenData || tokenHasExpired(tokenData)) {
      valid = false;
    }

    if (tokenData?.key.indexOf('anonymous') === -1) {
      valid = false;
    }

    if (!valid) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('teamId');

      const loginInfo: OAuthTokenData['login_info'] = await window.FeloSDK.loginAnonymous();

      accessToken = loginInfo.access_token;
      window.sessionStorage.setItem('token', loginInfo.access_token);
    }

    const {
      data: { success, result },
    } = await querySelfInfo();

    if (!success || !result) {
      throw new Error('user not found');
    }

    const userInfo = {};

    dispatch({
      type: ActionKind.LOGIN,
      payload: {
        isAnonymous: true,
        accessToken: accessToken || '',
        user: userInfo,
        organization: undefined,
      },
    });
  });

  const login = useEvent(async ({ teamId, redirectUrl }: { teamId?: number; redirectUrl?: string }) => {
    dispatch({ type: ActionKind.SET_LOADING, payload: true });
    try {
      const tokenList = await window.FeloSDK.login({
        anonymously: true,
        redirectUrl,
      });
      if (tokenList && tokenList.length > 0) {
        await loginFromTeam(teamId, tokenList);
        return;
      }
      await loginFromAnonymous();
    } catch (error) {
      Cookies.remove('fid', { path: '' });
      Cookies.remove('vid', { path: '' });
      Cookies.remove('Authorization', { path: '' });
      // 为啥要清除teamid ???
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('teamId');
    } finally {
      dispatch({ type: ActionKind.SET_LOADING, payload: false });
    }
  });

  const logout = useEvent(() => {
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('teamId');

    dispatch({ type: ActionKind.LOGOUT });
  });

  const updateUser = useEvent(async () => {
    const { data } = await querySelfInfo();
    data.data.account = data.data.fele_user.email;
    data.data.organization = data.data.felo_team.team_name;
    dispatch({ type: ActionKind.UPDATE_USER, payload: data.data });
  });

  return (
    <AuthContext.Provider
      value={{ state, dispatch, login, loginFromTeam, loginByTeam, loginFromAnonymous, logout, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
}
