import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Space, Button, Pagination, Table, Modal, Divider, message } from 'antd';
import { copyText } from '@/utils/common';
import { queryRelationList, queryInviteCode } from '@/api/api';
import SubMenu from '@/components/subMenu/index';
import { useAuthContext } from '@/hooks/auth';
import peoplesSvg from '@/assets/images/peoples.svg';
import CopySvg from '@/assets/images/copy.svg';
import './index.less';

function ClientList() {
  const { t } = useTranslation();
  const { Search } = Input;
  const { state, updateUser } = useAuthContext();
  const { user } = state;
  const [data, setData] = useState([]);
  const [curr, setCurr] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageType, setPageType] = useState(0);
  const [item, setItem] = useState({});
  const [code, setCode] = useState('');
  const [inviteCodeUrl, setInviteCodeUrl] = useState('');
  const [inviteMeetUrl, setInviteMeetUrl] = useState('');
  const onSearch = () => {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // 我的内容表头
  const column = [
    {
      title: t('registerAccount'),
      dataIndex: 'felo_account',
    },
    {
      title: t('organizationName'),
      dataIndex: 'felo_corp_name',
    },
    // {
    //   title: t('userNumber'),
    //   dataIndex: 'agencyAccount',
    // },
    // {
    //   title: t('versionInUse'),
    //   dataIndex: 'agencyAccount',
    // },
    // {
    //   title: t('authorizationNumber'),
    //   dataIndex: 'agencyAccount',
    // },
    // {
    //   title: t('totalPackageValue'),
    //   dataIndex: 'agencyAccount',
    // },
    // {
    //   title: t('additionalCharges'),
    //   dataIndex: 'agencyAccount',
    // },
    // {
    //   title: t('versionValidityTime'),
    //   dataIndex: 'agencyAccount',
    // },
  ];
  const onChange = () => {};
  const initData = () => {
    queryRelationList(1).then((res) => {
      for (let i = 0; i < res.data.data.records.length; i++) {
        res.data.data.records[i].key = res.data.data.records[i].open_id;
      }
      setData(res.data.data.records);
      setTotal(res.data.data.total);
      setCurr(res.data.data.current);
    });
    queryInviteCode().then((res) => {
      setCode(res.data.data.proxy_invite_code);
      setInviteCodeUrl(`${import.meta.env.VITE_FELO_TOKEN_URL}/signin/login?recommend=${res.data.data.proxy_invite_code}`);
      setInviteMeetUrl(`${import.meta.env.VITE_FELO_MEET_URL}?recommend=${res.data.data.proxy_invite_code}`);
    });
  };
  const pageSizeOptions = [10, 20, 50, 100];
  useEffect(() => {
    initData();
  }, []);
  const copyClick = () => {
    copyText(inviteCodeUrl, () => {
      message.success(t('copySuccess'));
    });
  };
  const copyMeetClick = () => {
    copyText(inviteMeetUrl, () => {
      message.success(t('copySuccess'));
    });
  };
  const onCurrChange = (page:number, size:number) => {
    queryRelationList(page).then((res) => {
      for (let i = 0; i < res.data.data.records.length; i++) {
        res.data.data.records[i].key = res.data.data.records[i].open_id;
      }
      setData(res.data.data.records);
      setTotal(res.data.data.total);
      setCurr(res.data.data.current);
    });
  };
  return (
    <div className="agency-content">
      <div className="content-title">
        <SubMenu menuText={t('clientList')} />
        {/* <Search className="right-search" placeholder="input search text" onSearch={onSearch} style={{ width: 200 }} /> */}
      </div>
      <div className="content-center">
        <div className="content-table">
          <div className="content-header">
            <div className="client-content-left">
              <div className="content-organ">{user.organization}</div>
              <Button className="add-button" onClick={showModal} type="primary">
                {t('invitationPromotion')}
              </Button>
            </div>
            <div className="client-content-right">
              <img className="people-img" src={peoplesSvg}></img>
              <div>
                <div>{t('promotePeoples')}</div>
                <div className="people-unit">
                  <span className="people-span">{total}</span>
                  {t('people')}
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="table-title">{t('dataList')}</div>
          <Table
            pagination={false}
            columns={column}
            dataSource={data}
            onChange={onChange}
            className='client-details'
            locale={{ emptyText: t('NoData') }}
            bordered
            rowClassName={(record, index) => {
              let className = '';
              className = index % 2 === 0 ? 'ou' : 'ji';
              // console.log(className)
              return className;
            }}
          />
        </div>

        <div className="pagination-div">
          <Pagination
            current={curr}
            style={{ marginLeft: 'auto', marginRight: '20px' }}
            total={total}
            onChange={onCurrChange}
            size="small"
            defaultPageSize={10}
            showTotal={() => `${t('total')} ${total} ${t('item')}`}
            pageSizeOptions={pageSizeOptions}
          />
        </div>
      </div>
      <Modal
        title={t('invitationPromotion')}
        open={isModalOpen}
        width={550}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        className="model-invitation"
      >
        <div className="model-content">
          <div className="model-title">{t('invitationPromotionUse')}</div>
          <div className="content-background">
            <div className="background-title">{t('promotionLink')}</div>
            <div className="background-link">{inviteCodeUrl}</div>
            <Button className="background-button" style={{ borderRadius: '4px' }} type="primary" onClick={copyClick}>
              {t('copyLink')}
            </Button>
          </div>
          <div className="content-info">
            <div className="info-value">{t('otherPromotion')}:</div>
          </div>
          <div className="content-info">
            <div className="info-title">{t('promotionCode')}:</div>
            <div className="info-value">{code}</div>
          </div>
          <div className="content-info">
            <div className="info-title">{t('OfficialWebsitePromotionLink')}:</div>
            <div className="info-content">
              <div className="info-value">{inviteMeetUrl}</div>
              <img className="copy-img" src={CopySvg} onClick={copyMeetClick}></img>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ClientList;
