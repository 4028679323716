import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Form, Input, Select, message, Divider } from 'antd';
import { useAuthContext } from '@/hooks/auth';
import { editStaff } from '@/api/api';
import './index.less';

function AccountInfo() {
  const { t } = useTranslation();
  const { state, updateUser } = useAuthContext();
  const { user } = state;
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const onFinish = (values: any) => {
    editStaff(
      values.bank_card_no,
      values.contact_email,
      values.contacts_phone,
      values.contacts,
      user.open_id,
      values.memo,
    ).then((res) => {
      if (res.data.status.ok) {
        message.success(t('editSuccess'));
        updateUser();
      } else {
        message.error(t('editError'));
      }
    });
  };
  return (
    <div className="form-account-div">
      <div className='account-title'>{user.organization}</div>
      <div className='from-content'>
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={user}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className='form-custom'
        >
          <Form.Item
            label={t('account')}
            name="account"
          >
            <Input className='border-radius-two' placeholder={t('inputAccount')} disabled={true} />
          </Form.Item>

          <Form.Item
            label={t('organization')}
            name="organization"
          >
            <Select className='border-radius-two' placeholder={t('selectOrganization')} disabled={true}/>
          </Form.Item>
          <Divider/>
          <Form.Item
            label={t('contacts')}
            name="contacts"
          >
            <Input className='border-radius-two' placeholder={t('inputContacts')}/>
          </Form.Item>
          <Form.Item
            label={t('emailAddress')}
            name="contact_email"
          >
            <Input className='border-radius-two' placeholder={t('inputEmailAddress')}/>
          </Form.Item>
          <Form.Item
            label={t('phone')}
            name="contacts_phone"
          >
            <Input className='border-radius-two' placeholder={t('inputPhone')}/>
          </Form.Item>
          <Form.Item
            label={t('bankAccount')}
            name="bank_card_no"
          >
            <Input className='border-radius-two' placeholder={t('inputBankAccount')}/>
          </Form.Item>
          <Form.Item
            label={t('remark')}
            name="memo"
          >
            <Input className='border-radius-two' placeholder={t('inputRemark')}/>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button htmlType="submit" style={{ borderRadius: '4px' }} className='editBUtton'>
            {t('edit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default AccountInfo;
