import { createContext, Dispatch } from 'react';
import { OAuthTokenData, UserDetailInfo } from '@/types';

export interface AuthState {
  loading: boolean;

  error: string | undefined;

  authenticated: boolean;

  isAnonymous: boolean;

  accessToken: string | undefined;

  user: any;

  organization: OAuthTokenData['team'] | undefined;
}

export const initialState: AuthState = {
  loading: true,
  error: undefined,
  authenticated: false,
  isAnonymous: false,
  accessToken: undefined,
  user: undefined,
  organization: undefined,
};

export enum ActionKind {
  SET_LOADING,
  SET_ERROR,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
}

export type Action =
  | {
      type: ActionKind.SET_LOADING;
      payload: boolean;
    }
  | {
      type: ActionKind.SET_ERROR;
      payload: Error | undefined;
    }
  | {
      type: ActionKind.LOGIN;
      payload: {
        isAnonymous: boolean;
        accessToken: string;
        user: any;
        organization: OAuthTokenData['team'] | undefined;
      };
    }
  | {
      type: ActionKind.LOGOUT;
    }
  | {
      type: ActionKind.UPDATE_USER;
      payload: {
        user: any;
      };
    };

export const AuthContext = createContext<{
  state: AuthState;
  dispatch: Dispatch<Action>;
  loginFromTeam: (teamId: number | undefined, tokenList: OAuthTokenData[]) => Promise<void>;
  loginByTeam: (teamId: number) => Promise<void>;
  loginFromAnonymous: () => Promise<void>;
  login: (data: { teamId?: number | undefined; redirectUrl?: string }) => Promise<void>;
  logout: () => void;
  updateUser: () => void;
}>({
  state: initialState,
  dispatch: () => null,
  loginFromTeam: () => Promise.resolve(),
  loginByTeam: () => Promise.resolve(),
  loginFromAnonymous: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => null,
  updateUser: () => null,
});
