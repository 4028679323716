import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const rootElement = document.getElementById('root') as HTMLElement;
window.start = new Date().getTime();
console.log('>> start time', window.start);

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement,
);
