import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Menu from '../menu/index';
import Banner from '@/components/Banner';
import { useAuthContext } from '@/hooks/auth';
import './index.less';

function Login() {
  const { state } = useAuthContext();
  const { t } = useTranslation();
  const { user } = state;
  return (
    <div>
      <Banner/>
      {
        user?.rule_types ? <Menu/> : <div className='noPermission-div'>{t('noPermission')}</div>
      }
    </div>

  );
}

export default Login;
