import { Route, BrowserRouter, Routes } from 'react-router-dom';

import Pages from '@/pages';
import Wrapper from '@/components/layouts/Layout';
import { PrivateRoute } from '@/components/AuthProtection/PrivateRoute';
import { MainLayout } from '@/pages/layouts/MainLayout';

function RouterContainer() {
  return (
      <Routes>
        <Route element={<MainLayout />}>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Pages.Login />
              </PrivateRoute>
            }
          />
        </Route>

        <Route element={<Wrapper />}>
          <Route path="/dash" element={<Pages.Dash />} />
        </Route>
      </Routes>
  );
}

export default RouterContainer;
