import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import enUS from 'antd/lib/locale/en_US';
import zhTW from 'antd/lib/locale/zh_TW';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import i18n from '@/utils/i18n';

const getAntdLocale = (lang: string) => {
  switch (lang) {
    case 'en':
    case 'en-US':
      return enUS;
    case 'zh-TW':
      return zhTW;
    case 'ja':
    case 'ja-JP':
    default:
      return jaJP;
  }
};

export function AntdProvider({ children }: PropsWithChildren<unknown>) {
  const [antdLocale, setAntdLocale] = useState(() => getAntdLocale(i18n.language));

  const handleLanguageChange = useCallback((lang: string) => {
    setAntdLocale(getAntdLocale(lang));
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChange);

    return () => i18n.off('languageChanged', handleLanguageChange);
  }, [handleLanguageChange]);

  return <ConfigProvider locale={antdLocale}>{children}</ConfigProvider>;
}

export default AntdProvider;
